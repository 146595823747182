<template>
  <div>
    <nav class="nav nav-pills mb-3">
      <router-link :to="{name:'Admin List'}" class="mr-3 nav-link" v-if="$auth.check('admins_page_tab')" exact-active-class="active" >Admins</router-link>
      <router-link :to="{name:'Roles'}" class="mr-3 nav-link" v-if="$auth.check('roles_page_tab')" exact-active-class="active">Roles</router-link>
      <router-link :to="{name:'Permissions'}" class="nav-link" v-if="$auth.check('permissions_page_tab')" exact-active-class="active">Permissions</router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Admins",
  created() {
    if (this.$route.name === 'Admin List' && !this.$auth.check('admins_page_tab')) {
      this.$router.push({name: 'Roles'});
      return;
    }
    if (this.$route.name === 'Roles' && !this.$auth.check('roles_page_tab')) {
      this.$router.push({name: 'Permissions'});
      return;
    }
    if (this.$route.name === 'Permissions' && !this.$auth.check('permissions_page_tab')) {
      this.$router.push({name: 'Admins'});
      return;
    }
  }
}
</script>

<style scoped>

</style>