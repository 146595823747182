<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Categories <span class="text-secondary"
                                                                                v-c-tooltip="'These categories will be used for the End User Dashboard page in the order that set on this page'"><i
          class="far fa-question-circle"></i></span></h2>
      <CButton @click="createClass" class="ml-auto" color="primary">Add New</CButton>
    </CCardHeader>
    <CCardBody>

      <div class="position-relative table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
          </thead>
          <draggable v-model="data" tag="tbody" @end="saveOrder()">
            <tr v-for="item in data" :key="'item-'+item.id">
              <td scope="row">{{ item.order }}</td>
              <td>{{ item.name }}</td>
              <td style="width: 20%">
                <div class="d-flex flex-nowrap align-items-center">
                  <CButton size="sm" color="warning" class="text-white mr-2" @click="askHide(item)" v-if="item.visible">
                    Hide
                  </CButton>
                  <CButton size="sm" color="success" class="text-white mr-2" @click="askShow(item)"
                           v-if="!item.visible">Show
                  </CButton>
                  <CButton size="sm" color="warning" class="text-white mr-2" @click="editClass(item.id)">Edit</CButton>
                  <CButton size="sm" color="danger" class="text-white" @click="deleteClass(item.id)">Delete</CButton>

                  <span class="mx-3">
                      <i class="fas fa-arrows-alt"></i>
                  </span>
                </div>
              </td>
            </tr>
          </draggable>
        </table>
        <CElementCover v-if="loading"
                       :boundaries="[
            { sides: ['top'], query: 'td' },
            { sides: ['bottom'], query: 'tbody' }
          ]"
        />
      </div>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
    <default-confirm-action-modal ref="show_modal" @confirmed="show"></default-confirm-action-modal>
    <default-confirm-action-modal ref="hide_modal" @confirmed="hide"></default-confirm-action-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import DeleteConfirmModal from "@/views/sessions/DeleteConfirmModal";
import draggable from 'vuedraggable'
import DefaultConfirmActionModal from "@/components/DefaultConfirmActionModal";

export default {
  name: "ClassCategories",
  components: {DefaultConfirmActionModal, DeleteConfirmModal, draggable},
  mixins: [externalTable],
  data() {
    return {
      url: '/categories'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http.get(this.url, {params: {...this.options, filters: this.filters}}).then(({data}) => {
        this.data = data.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    createClass() {
      this.$router.push({name: 'New Category'});
    },
    editClass(id) {
      this.$router.push({name: 'Edit Category', params: {id: id}});
    },
    deleteClass(id) {
      this.$refs.delete_modal.open(id);
    },
    confirmDelete(id) {
      this.$http.delete('/categories/' + id + '').then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    askShow(item) {
      this.$refs.show_modal.open(item)
    },
    askHide(item) {
      this.$refs.hide_modal.open(item)
    },
    hide(item) {
      this.$http.put('/categories/' + item.id + '/hide').then(() => {
        this.$noty.success('Updated!');
        this.fetchData()
      })
    },
    show(item) {
      this.$http.put('/categories/' + item.id + '/show').then(() => {
        this.$noty.success('Updated!');
        this.fetchData()
      })
    },
    saveOrder() {
      this.$http.put('/categories/order', {
        categories: this.data.map(item => {
          return item.id
        })
      }).then(() => {
        this.$noty.success('Order Changed!');
        this.fetchData()
      })
    }
  },
  computed: {}
}
</script>

<style scoped>
.status-badge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
</style>