import Vue from 'vue'
import Router from 'vue-router'
import ForgotPassword from "@/views/pages/ForgotPassword";
import ResetPassword from "@/views/pages/ResetPassword";
import Admins from "@/views/admins/Admins";
import AdminList from "@/views/admins/AdminList";
import RoleList from "@/views/admins/RoleList";
import Permissions from "@/views/admins/Permissions";
import NewAdmin from "@/views/admins/NewAdmin";
import NewRole from "@/views/admins/NewRole";
import InstructorList from "@/views/instructors/InstructorList";
import InviteInstructor from "@/views/instructors/InviteInstructor";
import EditInstructor from "@/views/instructors/EditInstructor";
import Page403 from "@/views/pages/Page403";
import UserList from "@/views/users/UserList";
import EditUser from "@/views/users/EditUser";
import Classes from "@/views/sessions/Classes";
import ClassList from "@/views/sessions/ClassList";
import NewClass from "@/views/sessions/NewClass";
import Categories from "@/views/sessions/Categories";
import NewCategory from "@/views/sessions/NewCategory";
import ClassCalendar from "@/views/sessions/ClassCalendar";
import List from "@/views/HelpVideos/List";
import StudioList from "@/views/studios/StudioList";
import AddStudio from "@/views/studios/AddStudio";
import EditAdminProfile from "@/views/admins/EditAdminProfile";
import AudioUpload from "@/views/sessions/segments/AudioUpload";
import AudioSegmentation from "@/views/sessions/segments/AudioSegmentation";
import SubscriptionCancelReasons from "@/views/users/SubscriptionCancelReasons";
import EnterpriseUserList from "@/views/enterprise/EnterpriseUserList";
import EditEnterpriseUser from "@/views/enterprise/EditEnterpriseUser";
import EnterpriseUserSubscriptions from "@/views/enterprise/EnterpriseUserSubscriptions";
import EditEnterpriseUserSubscription from "@/views/enterprise/EditEnterpriseUserSubscription";
import Reports from "@/views/Reports";
import EnterpriseBuilds from "@/views/builds/EnterpriseBuilds";
import Languages from "@/views/Languages/Languages.vue";
import SpinnStudioLanguages from "@/views/Languages/SpinnStudioLanguages.vue";


const TheContainer = () => import('@/containers/TheContainer')
const Dashboard = () => import('@/views/Dashboard')
const Login = () => import('@/views/pages/Login')
Vue.use(Router)

Vue.router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({y: 0}),
  routes: configRoutes(),
})
let checkPermission = async (permissions) => {
  if (Vue.auth.ready()) {
    const check = Vue.auth.check;
    return permissions.find(value => check(value));
  } else {
    let {data} = await Vue.auth.fetch()
    return permissions.find(value => data.data.permissions.includes(value));
  }
  return false;
}
Vue.router.beforeEach(async  (to, from, next) => {
  if (to.meta.permissions && typeof to.meta.permissions === "object" && to.meta.permissions.length > 0) {
    if (await checkPermission(to.meta.permissions)) {
      next()
    } else {
      next({name: 'Forbidden'})
    }
  }
  next()
})

function configRoutes() {
  return [
    {
      path: '/',
      name: '',
      component: TheContainer,
      // beforeEnter: checkPermission,
      meta: {auth: true,},
      children: [
        {
          path: '',
          name: 'Dashboard',
          meta: {lang: 'Dashboard'},
          component: Dashboard
        },
        {
          path: '/profile',
          name: 'Profile',
          meta: {lang: 'Profile'},
          component: EditAdminProfile,
        },
        {
          path: '/admins',
          name: 'Admins',
          meta: {lang: 'Manage Admins'},
          component: Admins,
          redirect: '/admins/list',
          children: [
            {
              path: 'list',
              name: 'Admin List',
              meta: {lang: 'Admin List', permissions: ['admins_page_tab']},
              component: AdminList
            },
            {
              path: 'new',
              name: 'New Admin',
              meta: {lang: 'New Admin', permissions: ['admins_crud']},
              component: NewAdmin
            },
            {
              path: ':id/edit',
              name: 'Edit Admin',
              meta: {lang: 'Edit Admin', permissions: ['admins_crud']},
              component: NewAdmin,
              props: true,
            },
            {
              path: 'roles',
              name: 'Roles',
              meta: {lang: 'Role List', permissions: ['roles_page_tab']},
              component: RoleList
            },
            {
              path: 'roles/new',
              name: 'New Role',
              meta: {lang: 'New Role', permissions: ['roles_crud']},
              component: NewRole
            },
            {
              path: 'roles/:id/edit',
              name: 'Edit Role',
              meta: {lang: 'Edit Role', permissions: ['roles_crud']},
              component: NewRole,
              props: true,
            },
            {
              path: 'permissions',
              name: 'Permissions',
              meta: {lang: 'Permissions', permissions: ['permissions_page_tab']},
              component: Permissions
            },
          ]
        },
        {
          path: '/instructors',
          meta: {lang: 'Manage Instructors'},
          component: {
            render(h) {
              return h('router-view');
            }
          },
          children: [
            {
              path: '',
              name: 'Instructors',
              meta: {lang: 'Instructors', permissions: ['instructors_page_tab']},
              component: InstructorList
            },
            {
              path: 'invite',
              name: 'Invite Instructor',
              meta: {lang: 'Invite', permissions: ['instructors_create_invite']},
              component: InviteInstructor
            },
            {
              path: ':id/edit',
              name: 'Edit Instructor',
              meta: {lang: 'Edit Instructor', permissions: ['instructors_crud']},
              component: EditInstructor,
              props: true,
            },
          ]
        },
        {
          path: '/users',
          meta: {lang: 'End Users Administration'},
          component: {
            render(h) {
              return h('router-view');
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              meta: {lang: 'Users'},
              component: UserList
            },
            {
              path: 'reasons',
              name: 'UsersReasons',
              meta: {lang: 'User Reasons'},
              component: SubscriptionCancelReasons
            },
            // {
            //   path: 'new',
            //   name: 'New User',
            //   meta: {lang: 'New User'},
            //   component: EditUser
            // },
            {
              path: ':id/edit',
              name: 'Edit User',
              meta: {lang: 'Edit User'},
              component: EditUser,
              props: true,
            },
          ]
        },

        {
          path: '/enterprise-users',
          meta: {lang: 'Enterprise Users'},
          component: {
            render(h) {
              return h('router-view');
            }
          },
          children: [
            {
              path: '',
              name: 'Enterprise Users',
              meta: {lang: 'Enterprise Users'},
              component: EnterpriseUserList
            },
            {
              path: 'new',
              name: 'New Enterprise User',
              meta: {lang: 'New Enterprise User'},
              component: EditEnterpriseUser
            },
            {
              path: ':id/edit',
              name: 'Edit Enterprise User',
              meta: {lang: 'Edit Enterprise User'},
              component: EditEnterpriseUser,
              props: true,
            },
            {
              path: ':id/subscriptions',
              name: 'Enterprise User Subscriptions',
              meta: {lang: 'Enterprise User Subscriptions'},
              component: EnterpriseUserSubscriptions,
              props: true,
            },
            {
              path: ':id/subscriptions/new',
              name: 'New Enterprise User Subscription',
              meta: {lang: 'New Enterprise User Subscription'},
              component: EditEnterpriseUserSubscription,
              props: true,
            },
          ]
        },

        {
          path: '/classes',
          name: 'Classes Administration',
          meta: {lang: 'Classes Administration'},
          component: Classes,
          redirect: '/classes/list',
          children: [
            {
              path: 'list',
              name: 'ClassList',
              meta: {lang: 'Classes', permissions: ['classes_list_page_tab']},
              component: ClassList
            },
            {
              path: 'new',
              name: 'New Class',
              meta: {lang: 'New Class', permissions: ['classes_crud']},
              component: NewClass
            },
            {
              path: ':id/edit',
              name: 'Edit Class',
              meta: {lang: 'Edit Class', permissions: ['classes_crud']},
              component: NewClass,
              props: true,
            },
            {
              path: ':id/audio',
              name: 'Edit Class Audio',
              meta: {lang: 'Edit Class Audio', permissions: ['classes_crud']},
              component: AudioUpload,
              props: true,
            },
            {
              path: ':id/segments',
              name: 'Edit Class Segments',
              meta: {lang: 'Edit Class Segments', permissions: ['classes_crud']},
              component: AudioSegmentation,
              props: true,
            },
            {
              path: 'categories',
              name: 'ClassCategories',
              meta: {lang: 'Categories', permissions: ['classes_categories']},
              component: Categories
            },
            {
              path: 'categories/new',
              name: 'New Category',
              meta: {lang: 'New Category', permissions: ['classes_categories']},
              component: NewCategory
            },
            {
              path: 'categories/:id/edit',
              name: 'Edit Category',
              meta: {lang: 'Edit Category', permissions: ['classes_categories']},
              component: NewCategory,
              props: true,
            },
            {
              path: 'categories/calendar',
              name: 'ClassCalendar',
              meta: {lang: 'Calendar', permissions: ['classes_calendar']},
              component: ClassCalendar,
            },
            {
              path: 'videos',
              name: 'HelpVideo',
              meta: {lang: 'Help Videos', permissions: ['help_video_page_tab']},
              component: List,
            },
            {
              path: 'studios',
              meta: {lang: 'Studios'},
              component: {
                render(h) {
                  return h('router-view');
                }
              },
              children: [
                {
                  path: '',
                  name: 'Studios',
                  meta: {lang: 'Studios', permissions: ['studios_page_tab']},
                  component: StudioList
                },
                {
                  path: 'create',
                  name: 'New Studio',
                  meta: {lang: 'New Studio', permissions: ['studios_page_tab']},
                  component: AddStudio
                },
                {
                  path: ':id/edit',
                  name: 'Edit Studio',
                  meta: {lang: 'Edit Studio', permissions: ['studios_page_tab']},
                  component: AddStudio,
                  props: true,
                },
              ]
            },
          ]
        },
        {
          path: '/reports',
          name: 'Reports',
          component: Reports,
        },
        {
          path: '/enterprise-builds',
          name: 'EnterpriseBuilds',
          component: EnterpriseBuilds,
        },
        {
          path: '/403',
          name: 'Forbidden',
          component: Page403,
        },

        {
          path: '/languages',
          name: 'Languages',
          meta: {lang: 'Languages'},
          component: Languages,
          redirect: '/languages/spin-studio',
          children: [
            {
              path: 'spin-studio',
              name: 'SpinStudioLanguages',
              meta: {lang: 'SpinStudio Languages'},
              component: SpinnStudioLanguages
            },
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {auth: false},
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword,
      meta: {auth: false},
    },
    {
      path: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
      meta: {auth: false},
    },
  ]
}

export default Vue.router;