<template>
  <CModal
      :show.sync="showModal"
      centered
      @update:show="closeModal"
      title="Family Users"
  >
    <div class="d-flex" v-if="loading">
      <c-spinner></c-spinner>
    </div>
    <ul v-else class="mb-0">
      <li v-for="u in users">
        {{u.invited_user.profile.first_name}} {{u.invited_user.profile.last_name}} ({{statuses[u.status]}})
      </li>
    </ul>
    <template #footer-wrapper>
      <div class="d-flex my-0 align-items-center justify-content-center">

      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "FamilyUsersModal",
  data() {
    return {
      user:null,
      users:[],
      showModal: false,
      loading: true,
      statuses: {
        0: 'Pending',
        1: 'Active',
        2: 'Decline',
        3: 'Canceled',
      }
    }
  },
  methods: {
    open(user) {
      this.showModal = true;
      this.user = Object.assign({}, user);
      this.loadUsers(user);
    },
    closeModal() {
      this.$emit('modal:close');
      document.querySelector('body').classList.remove('modal_open')
    },
    loadUsers(user){
      this.axios.get('/users/'+user.id+'/family')
      .then(({data})=>{
        this.users = data.data
        this.loading = false;
      })
    }
  },
  computed: {
  }
}
</script>

<style scoped>
</style>