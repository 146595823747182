<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">Help video</h2>
      <CButton @click="addVideo" class="ml-auto mr-3" color="primary">Add Video</CButton>
      <CButton @click="addCategory" class="" color="primary">Add Category</CButton>
    </CCardHeader>
    <CCardBody>
      <draggable v-model="data" tag="div" @end="categoryOrderChanged">
        <div v-for="category in data">
          <div class="d-flex py-2 align-items-center">
            <h4 class="mb-0">{{ category.name }} <i class="ml-3 fas fa-arrows-alt"></i></h4>
            <CButton @click="editCategory(category)" class="ml-3 mr-3 text-white" color="warning">Edit</CButton>
            <CButton @click="deleteCategory(category.id)" class="ml-auto" color="danger">Delete</CButton>
          </div>
          <div class="position-relative table-responsive">
            <table class="table">
              <thead>
              <tr class="bg-secondary">
                <th>Name</th>
                <th>Actions</th>
              </tr>
              </thead>
              <draggable v-model="category.videos" tag="tbody" group="videos" @change="videoOrderChanged(category.id)">
                <tr v-for="item in category.videos" :key="'item-'+item.id">
                  <td scope="row"> {{ item.name }}</td>
                  <td style="width: 120px">
                    <div class="d-flex flex-nowrap align-items-center justify-content-between">
                      <CButton size="sm" color="danger" class="text-white" @click="deleteVideo(item.id)">Delete
                      </CButton>
                      <span>
                      <i class="fas fa-arrows-alt"></i>
                      </span>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <CElementCover v-if="loading"
                           :boundaries="[
              { sides: ['top'], query: 'td' },
              { sides: ['bottom'], query: 'tbody' }
            ]"
            />
          </div>
        </div>
      </draggable>
    </CCardBody>
    <add-category-modal ref="add_category_modal" @created="categoryCreated('Created!')"
                        @updated="categoryCreated('Updated!')"></add-category-modal>
    <add-video-modal ref="add_video_modal" :categories="data" @created="categoryCreated('Created!')"></add-video-modal>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
    <delete-confirm-modal ref="delete_modal_category" @confirmed="confirmDeleteCategory"></delete-confirm-modal>
  </CCard>
</template>

<script>
import DeleteConfirmModal from "@/views/HelpVideos/DeleteConfirmModal";
import draggable from "vuedraggable";
import externalTable from "@/mixins/externalTable";
import AddCategoryModal from "@/views/HelpVideos/AddCategoryModal";
import AddVideoModal from "@/views/HelpVideos/AddVideoModal";

export default {
  name: "HelpVideo",
  components: {AddVideoModal, AddCategoryModal, DeleteConfirmModal, draggable},
  mixins: [externalTable],
  data() {
    return {
      url: '/help-categories',
      videoLoading: 0,
    }
  },
  watch: {
    videoLoading: function (val, oldVal){
      if(val > 0 && oldVal === 0){
        this.loading = true;
      }
      if(val === 0 && oldVal > 0){
        this.loading = false;
        this.$noty.success('Order Changed!');
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    categoryCreated(msg) {
      this.$noty.success(msg);
      this.fetchData()
    },
    addVideo() {
      this.$refs.add_video_modal.open();
    },
    addCategory() {
      this.$refs.add_category_modal.open();
    },
    videoOrderChanged(category) {
      this.videoLoading += 1;
      this.$http.post('/help-videos/order', {
        category: category,
        videos: this.data.find(item => item.id === category).videos.map(item => item.id)
      }).then(() => {
      }).finally(() => {
        this.videoLoading -= 1;
      });
    },
    categoryOrderChanged() {
      this.loading = true;
      this.$http.post(this.url + '/order', {
        categories: this.data.map(item => item.id)
      }).then(() => {
        this.$noty.success('Order Changed!');
      }).finally(() => {
        this.loading = false;
      });
    },
    editCategory(category) {
      this.$refs.add_category_modal.open(category);
    },
    deleteCategory(id) {
      this.$refs.delete_modal_category.open(id);
    },
    confirmDeleteCategory(id) {
      this.$http.delete('/help-categories/' + id + '').then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true;
      this.$http.get(this.url).then(({data}) => {
        this.data = data.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    editVideo(id) {
      this.$router.push({name: 'Edit Category', params: {id: id}});
    },
    deleteVideo(id) {
      this.$refs.delete_modal.open(id);
    },
    confirmDelete(id) {
      this.$http.delete('/help-videos/' + id + '').then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    saveOrder() {
      this.$http.put('/help-videos/order', {
        videos: this.data.map(item => {
          return item.id
        }),
        type: 'how_to',
      }).then(() => {
        this.$noty.success('Updated!');
        this.fetchData()
      })
    }
  },
  computed: {}
}
</script>
