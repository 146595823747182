var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex justify-content-end"}),_c('CCardBody',[_c('vc-calendar',{staticClass:"custom-calendar w-100",attrs:{"masks":_vm.masks,"attributes":_vm.attributes,"disable-page-swipe":"","is-expanded":""},on:{"update:to-page":_vm.changePage},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
var title = ref.title;
return [(!_vm.loading)?_c('span',[_vm._v(_vm._s(title))]):_c('CSpinner',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"color":"primary","grow":""}})]}},{key:"day-content",fn:function(ref){
var day = ref.day;
var attributes = ref.attributes;
return [_c('div',{staticClass:"d-flex flex-column h-100 z-10 overflow-hidden hover-full"},[_c('span',{staticClass:"day-label "},[_vm._v(_vm._s(day.day))]),_c('div',{staticClass:"flex-grow overflow-y-auto overflow-x-auto"},_vm._l((attributes),function(attr){return _c('p',{key:attr.id,staticClass:"rounded px-1 py-0 mb-1",class:{
                  'bg-success text-white': attr.customData.status === 'released',
                  'bg-secondary': attr.customData.status === 'draft',
                  'bg-dark': attr.customData.status === 'ready',
                  'bg-info text-white': attr.customData.status === 'filmed',
                }},[_vm._v(" "+_vm._s(attr.customData.name)+" ")])}),0)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }