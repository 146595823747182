<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 class="text-uppercase font-weight-bold mb-0">Reports</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="save">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group "
                 :class="{'is-invalid': hasError('start_date')}">
              <label>Start Date</label>
              <vc-date-picker v-model="report.start_date" mode="date" :masks="masks">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      class="form-control "
                      :value="inputValue"
                      v-on="inputEvents"
                  />
                </template>
              </vc-date-picker>
              <div class="invalid-feedback d-block" v-if="hasError('start_date')">
                {{ getError('start_date') }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group "
                 :class="{'is-invalid': hasError('end_date')}">
              <label>End Date</label>
              <vc-date-picker v-model="report.end_date" mode="date" :masks="masks">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      class="form-control "
                      :value="inputValue"
                      v-on="inputEvents"
                  />
                </template>
              </vc-date-picker>
              <div class="invalid-feedback d-block" v-if="hasError('end_date')">
                {{ getError('end_date') }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <CSelect v-model="report.report_type" :options="[{value:'users', label: 'Users'}, {value:'activity', label:'Activity'}]" label="Report Type"
                     @update:value="report.report_type = $event"
                     :addInputClasses="{'is-invalid': hasError('report_type')}"
                     :invalid-feedback="getError('report_type')"></CSelect>
          </div>

          <div class="col-md-6">
            <div class="form-group s-select"
                 :class="{'is-invalid': hasError('users')}">
              <label>Users</label>
              <multiselect v-model="report.users"
                           placeholder="Enter emails" :multiple="true" :taggable="true" @tag="addTag" :options="dataForTagSelect(users)">
                <template slot="option" slot-scope="props">
                  <div data-v-6b8bd229="" tabindex="0" class="c-multi-select-option "
                       :class="{'c-multi-selected':report.users.includes(props.option)}"> {{ props.option && props.option.label ? props.option.label : props.option  }}
                  </div>
                </template>

              </multiselect>
              <div class="invalid-feedback d-block" v-if="hasError('users')">
                {{ getError('users') }}
              </div>
              <template v-if="hasError('users.'+index)">
                <div class="invalid-feedback d-block" v-for="(user, index) in report.users" :key="'err-i-'+index">
                  {{ getError('users.'+index) }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <CButton type="submit" color="primary" class="mt-3">Generate Report</CButton>
      </form>
    </CCardBody>
  </CCard>
</template>
<script>

import hasApiValidation from "@/mixins/hasApiValidation";
import Multiselect from 'vue-multiselect'
export default {
  name: 'Reports',
  mixins: [hasApiValidation],
  components: {
    Multiselect
  },
  data() {
    return {
      masks: {
        inputDateTime24hr: 'YYYY-MM-DD',
      },
      report: {
        start_date: new Date(),
        end_date: new Date(),
        report_type: 'activity',
        users: [
            'rdegtjarewsky@maddogg.com',
            'smcdaniel@maddogg.com',
            'hcho@spinning.com',
        ],
      },
      users: [
        'rdegtjarewsky@maddogg.com',
        'smcdaniel@maddogg.com',
        'hcho@spinning.com',
      ],
    }
  },
  mounted() {
  },
  methods: {
    save() {
      this.setErrors({});
      this.$http.post('/reports/activity', this.report).then((data) => {
        this.$noty.success('Once ready, report will sent on selected emails');
        this.setErrors({});
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    addTag(newTagName) {
      this.users.push({
        id: null, name: newTagName})
      this.report.users.push(newTagName)
    },
    dataForTagSelect(data) {
      return data && data.length ? data.map(item => item.name || item) : [];
    },
  },
  computed: {}
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.form-group.is-invalid >>> .c-multi-select {
  border-color: var(--danger);
}

div.s-select >>> span.multiselect__option {
  padding: 0;
  background: transparent !important;
  color: #1A1A1C !important;
  font-size: 14px;
}

div.s-select >>> span.multiselect__option:after {
  content: unset !important;
}

div.s-select >>> .multiselect__option--selected {
  font-weight: 400;
}

div.s-select >>> .multiselect__content-wrapper {
  /* position: absolute; */
  top: 100%;
  left: 0;
  /* display: none; */
  float: left;
  width: 100%;
  padding: 0.5rem 0.75rem;
  /* margin-top: 0.625rem; */
  font-size: 0.875rem;
  text-align: left;
  background-clip: padding-box;
  border-radius: 0.25rem;
  color: #1A1A1C;
  background-color: #fff;
  border-color: #d8dbe0;
  border: 0;
  box-shadow: 0 4px 5px 0 rgba(var(--elevation-base-color), .14), 0 1px 10px 0 rgba(var(--elevation-base-color), .12), 0 2px 4px -1px rgba(var(--elevation-base-color), .20);
}

div.s-select >>> .c-multi-select-option::after, div.s-select >>> .c-multi-select-option::before {
  top: 0.5rem;
}

div.s-select >>> .multiselect__tag {
  text-overflow: ellipsis;
  display: inline-block;
  height: 25px;
  margin: 2px;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #ebedef;
  border-color: #d8dbe0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #768192;
}
</style>