<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 class="mb-0">Invite Instructor</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off">
        <CInput
            label="First Name"
            v-model="new_instructor.first_name"
            autocomplete="first-name"
            :addInputClasses="{'is-invalid': hasError('first_name')}"
            :invalid-feedback="getError('first_name')"
        ></CInput>
        <CInput
            label="Last Name"
            v-model="new_instructor.last_name"
            autocomplete="last-name"
            :addInputClasses="{'is-invalid': hasError('last_name')}"
            :invalid-feedback="getError('last_name')"
        ></CInput>
        <CInput
            label="Email"
            v-model="new_instructor.email"
            autocomplete="email"
            :addInputClasses="{'is-invalid': hasError('email')}"
            :invalid-feedback="getError('email')"
        ></CInput>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "InviteInstructor",
  mixins: [hasApiValidation],
  data() {
    return {
      roles: [],
      new_instructor: {
        first_name: '',
        last_name: '',
        email: '',
      },
    }
  },
  mounted() {
  },
  methods: {
    submit() {
      this.save();
    },
    save() {
      this.setErrors({});
      this.$http.post('/instructors', this.new_instructor).then((data) => {
        this.$router.push({name: 'Instructors'}).then(() => {
          this.$noty.success('Created');
        })
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
  },
}
</script>

<style scoped>

</style>