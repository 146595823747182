var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex justify-content-between"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Audio File")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" Back ")]),_c('CButton',{attrs:{"color":"primary","disabled":!_vm.audio_url || !_vm.audio_name},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next "),_c('i',{staticClass:"fa fa-arrow-right"})])],1)]),(!_vm.audio_url && !_vm.loading && !_vm.audio_processing)?_c('CCardBody',[(!_vm.uploading)?_c('div',{staticClass:"d-flex flex-column align-items-center py-4"},[_c('button',{staticClass:"btn btn-primary rounded-pill text-center text-uppercase px-5 mb-3",on:{"click":function($event){return _vm.$refs.file_upload.click()}}},[_vm._v("Upload Audio File ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Upload MP3 file, with the 256 Kbps option")]),(_vm.hasError('audio'))?_c('div',{staticClass:"invalid-feedback d-block mt-3"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.getError('audio')))])]):_vm._e(),_c('input',{ref:"file_upload",staticClass:"form-control d-none",attrs:{"accept":"audio/mp3","type":"file"},on:{"change":_vm.select}})]):_vm._e(),(_vm.uploading)?_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col-md-6 mx-auto"},[_c('CProgressBar'),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({width: _vm.progress+'%'}),attrs:{"role":"progressbar"}})]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("0%")]),_c('span',[_vm._v("100%")])])],1)]):_vm._e()]):_vm._e(),(_vm.loading || _vm.audio_processing)?_c('CCardBody',{staticClass:"d-flex justify-content-center"},[_c('CSpinner',{attrs:{"size":"lg"}})],1):_vm._e(),_c('CCardBody',{style:({
    'opacity': ! (_vm.waveform_ready && !_vm.loading  && _vm.audio_url) ? '0%' : '100%',
    'height':  ! (_vm.waveform_ready && !_vm.loading  && _vm.audio_url) ? '0' : '',
    'padding': ! (_vm.waveform_ready && !_vm.loading  && _vm.audio_url) ? '0' : '',
  })},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-2"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.needTruncate),expression:"!needTruncate"}],staticClass:"color-primary "},[_vm._v(_vm._s(_vm.audio_name ? _vm.truncateAudioName : ''))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.needTruncate),expression:"needTruncate"}],staticClass:"color-primary ",attrs:{"data-bs-content":_vm.audio_name_formatted ? _vm.audio_name_formatted :''}},[_vm._v(_vm._s(_vm.audio_name ? _vm.truncateAudioName : ''))])]),_c('div',{attrs:{"id":"waveform"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-3"},[_c('a',{staticClass:"btn btn-primary rounded-circle  d-flex justify-content-center align-items-center text-white",staticStyle:{"width":"40px","height":"40px"},on:{"click":function($event){return _vm.togglePlay()}}},[_c('i',{staticClass:"fa ",class:{
            'fa-play' : !this.playing,
            'fa-pause' : this.playing,
          }})])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center my-4"},[_c('button',{staticClass:"btn btn-outline-primary rounded-pill px-4 text-uppercase fw-bold py-2",on:{"click":_vm.reUpload}},[_c('i',{staticClass:"fa fa-upload me-2"}),_vm._v(" Upload New File ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }