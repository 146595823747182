<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!id" class="mb-0">New Admin</h2>
      <h2 v-else class="mb-0">Edit Admin</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.user_loading">
        <CInput
            label="First Name"
            v-model="new_admin.first_name"
            autocomplete="first_name"
            :addInputClasses="{'is-invalid': hasError('first_name')}"
            :invalid-feedback="getError('first_name')"
        ></CInput>
        <CInput
            label="Last Name"
            v-model="new_admin.last_name"
            autocomplete="last_name"
            :addInputClasses="{'is-invalid': hasError('last_name')}"
            :invalid-feedback="getError('last_name')"
        ></CInput>
        <CInput
            label="Email"
            v-model="new_admin.email"
            autocomplete="email"
            :addInputClasses="{'is-invalid': hasError('email')}"
            :invalid-feedback="getError('email')"
        ></CInput>
        <CInput
            label="Password"
            type="password"
            v-model="new_admin.password"
            autocomplete="new-password"
            :addInputClasses="{'is-invalid': hasError('password')}"
            :invalid-feedback="getError('password')"
        ></CInput>
        <CInput
            label="Confirm Password"
            type="password"
            autocomplete="new-password"
            v-model="new_admin.password_confirmation"
            :addInputClasses="{'is-invalid': hasError('password_confirmation')}"
            :invalid-feedback="getError('password_confirmation')"
        ></CInput>
        <div class="mb-3">
          <label>Roles</label>
          <template v-if="!loading_roles">
            <CMultiSelect
                :options="dataForSelect"
                :selected="new_admin.roles"
                :selection="true"
                inline
                optionsEmptyPlaceholder="No options"
                searchPlaceholder="Search"
                selectionType="tags"
                @update="updateData"
                :class="{'s-has-error': hasError('roles') || getArrayErrors('roles.').length > 0}"
                class="mb-3"
            ></CMultiSelect>
            <div class="invalid-feedback d-block" v-if="hasError('roles') || getArrayErrors('roles.').length > 0">
              {{ getError('roles') }}
              <span class="d-block" v-for="err in getArrayErrors('roles.')">{{err[0]}}</span>
            </div>
          </template>
          <div v-else class="mb-3">
            <CSpinner
                style="width:2rem;height:2rem;"
                color="primary"
                grow
            />
          </div>
        </div>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "NewAdmin",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_admin: {
        last_name: '',
        first_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        roles: [],
      },
      loading_roles: true,
      user_loading: false,
    }
  },
  mounted() {
    if (this.id) {
      this.user_loading = true;
      this.fetchUser(this.id);
    }
    this.getRoles();
  },
  methods: {
    fetchUser(id) {
      this.$http.get('/admins/' + id).then(({data}) => {
        this.new_admin.id = data.data.id
        this.new_admin.first_name = data.data.first_name;
        this.new_admin.last_name = data.data.last_name;
        this.new_admin.email = data.data.email;
        this.new_admin.roles = data.data.roles.map(item => item.id);
      })
        .catch(()=>{
          this.$router.push({name:"Admin List"}).then(()=>{
            this.$noty.error('Not Found')
          })
        })
        .finally(() => {
        this.user_loading = false;
      })
    },
    getRoles() {
      this.loading_roles = true;
      this.$http.get('/admins/roles').then(({data}) => {
        this.roles = data.data;
      }).finally(() => {
        this.loading_roles = false;
      })
    },
    updateData(data) {
      this.new_admin.roles = data;
    },
    submit() {
      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.setErrors({});
      this.$http.post('/admins', this.new_admin).then((data) => {
        this.$router.push({name: 'Admin List'}).then(()=>{
          this.$noty.success('Created');
        })
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    update() {
      this.setErrors({});
      this.$http.put('/admins/' + this.id, this.new_admin).then((data) => {
        this.$router.push({name: 'Admin List'}).then(()=>{
          this.$noty.success('Updated');
        })
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    }
  },
  computed: {
    dataForSelect() {
      return this.roles.map(item => {
        return {
          value: item.id,
          text: item.name
        }
      })
    },
  }
}
</script>

<style scoped>

</style>