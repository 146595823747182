<template>
  <CModal
      :show.sync="showModal"
      :size="'sm'"
      centered
      @update:show="cancel"
  >

    <template #header-wrapper>
      <CCardHeader class="border-0">
        <h4 class="font-weight-bold text-center ">
          {{ title }}
        </h4>
      </CCardHeader>
    </template>
    <template #body-wrapper><div></div></template>
  </CModal>
</template>
<script>
export default {
  name: "DeleteItemModal",
  props: {
    title: {
      type: String,
      default: 'Are You Sure?'
    },
  },
  data() {
    return {
      m_object: null,
      data: null,
      showModal:false,
    }
  },
  mounted() {
  },
  methods: {
    open(data, type = 'segment') {
      this.data = data;
      this.type = type;
      this.showModal = true;
    },
    cancel(a,b,c) {
      if(c){
        this.$emit(this.type === 'segment' ? 'segment-confirm' : 'annotation-confirm', this.data)
      } else {
        this.$emit('cancel')
      }
      this.data = null;
      this.showModal = false;
    }
  }
}
</script>
<style scoped>
.rounded-xl {
  border-radius: 30px;
}

button.btn-close {
  background: none;
  font-size: 25px;
  padding: 0 !important;
  margin: -10px 0 0 0 !important;
  width: auto;
  height: auto;
}


</style>