import Vue from 'vue';
import axios    from 'axios';
import VueAxios from 'vue-axios';
import qs from 'qs';
import router from '../router'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status == 403) {
    router.push({ name: 'Forbidden' })
  }
  if (error.response.status == 401) {
    // router.push({ name: 'Login' }).catch((e)=>{
    //   console.error(e)
    // })
  }
  return Promise.reject(error)
});

Vue.use(VueAxios, axios);

export default axios;