<template>

  <CCard>
    <CCardHeader class="d-flex ">
      <h2 class="text-uppercase font-weight-bold mb-0">SpinStudio Languages</h2>
    </CCardHeader>
    <CCardBody>
      <ul class="nav nav-pills">
        <li class="nav-item" v-for="lang in languages" :key="'lang-'+lang">
          <a href="#" :class="{active: lang === activeLang}" class="nav-link"
                 @click.prevent.stop="changeLang(lang)">{{ lang }}
          </a>
        </li>
      </ul>

      <div class="d-flex" v-if="loading">
        <c-spinner></c-spinner>
      </div>
      <div class="py-3" v-else>
        <ul class="list-unstyled">
          <li v-for="(key, i) in data">
            <h3>{{ i }}: </h3>
            <ul class="list-unstyled pl-4">
              <li v-for="(value, j) in key">
                <template v-if="typeof value == 'string'">
                  <CInput v-model="data[i][j]" :label="j"></CInput>
                </template>
                <template v-else>
                  <h4>{{ j }}</h4>
                  <ul class="list-unstyled pl-4">
                    <li v-for="(val, k) in value">
                      <CInput v-model="data[i][j][k]" :label="k"></CInput>
                    </li>
                  </ul>
                </template>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <CButton color="primary" @click="saveLang">Save</CButton>
    </CCardBody>
  </CCard>
</template>
<script>

export default {
  name: 'SpinStudioLanguages',
  data() {
    return {
      activeLang: 'en',
      languages: [
        'en', 'es'
      ],
      data: {},
      loading:true,
    }
  },
  watch: {
    activeLang: function () {
      this.getLangData()
    }
  },
  async mounted() {
    await this.getLangData();
  },
  methods: {
    changeLang(lang) {
      this.activeLang = lang
    },
    async getLangData() {
      this.loading = true;
      const {data} = await this.$http.get('studio-languages/' + this.activeLang);
      this.data = data.data;
      this.loading = false;
    },

    async saveLang() {
      this.loading = true;
      await this.$http.post('studio-languages/' + this.activeLang, {translations: this.data});
      this.loading = false;
    }
  },
  computed: {}
}
</script>

<style>
</style>
