<template>
  <div class="c-app flex-row align-items-center ">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" lg="5">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4 px-5">
              <CForm class="d-flex flex-column">
                <p class="text-center font-weight-bold mb-4 text-uppercase">Forgot Password?</p>
                <CInput
                    :placeholder="'E-mail'"
                    autocomplete="email"
                    v-model="formData.email"
                    :addInputClasses="{'is-invalid': hasError('email', 'formData')}"
                    :invalid-feedback="getError('email', 'formData')"
                >
                  <template #append-content>
                    <i class="far fa-envelope"/>
                  </template>
                </CInput>
                <CButton color="primary" class="px-5 mb-3 align-self-center text-uppercase" @click="forgotPassword" :disabled="!this.validate() || success">Send
                </CButton>
                <router-link :to="{ name: 'Login'}" class="d-block text-center px-0 " v-if="!message">
                  Back to Login
                </router-link>
                <p class="text-center text-primary" v-else>{{ message }}</p>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import {email, required} from "vuelidate/lib/validators";
export default {
  name: 'ForgotPassword',
  mixins: [hasApiValidation],
  data() {
    return {
      formData: {
        email: '',
      },
      message: '',
      success: false,
    }
  },
  validations: {
    formData: {
      email: {
        required: required,
        email: email,
      },
    }
  },
  mounted() {
  },
  methods: {
    validate() {
      return !this.$v.formData.$anyError;
    },
    forgotPassword() {
      if (this.success) {
        return
      }
      this.$http.post('/auth/forgot-password', this.formData)
          .then(({data}) => {
            this.message = data.message;
            this.success = true;
            this.$noty.success(data.message);
            this.setErrors({});
          })
          .catch(({response}) => {
            if (response && response.data.message) {
              this.$noty.error(response.data.message);
            }
            this.setErrors(response.data.errors);
          });
    }
  },
  watch: {
    'formData.email': function () {
      this.$v.formData.email.$touch();
    },
  }
}
</script>
