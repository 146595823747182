<template>
  <CModal
      :show.sync="showModal"
      centered
      @update:show="closeModal"
      title="Filters"
      :key="renderIteration"
  >
    <!--    <CSelect multiple v-model="filters.roles" :options="dataForSelect(roles)" label="Roles"></CSelect>-->
    <template v-if="!data_loading">
      <CSelect v-model="filters.status" :options="statuses" label="Status"
               @update:value="updateData($event, 'status')"></CSelect>
      <div class="mb-3">
        <label>Instructor</label>
        <multi-select
            :options="dataForSelect(data_filters.instructors)"
            :selected="filters.instructor"
            :selection="true"
            optionsEmptyPlaceholder="No options placeholder"
            searchPlaceholder="Search"
            selectionType="tags"
            :search="true"
            @update="updateData($event, 'instructor')"
        ></multi-select>
      </div>
      <div class="mb-3">
        <label>Language</label>
        <multi-select
            :search="true"
            :options="dataForSelect(data_filters.languages)"
            :selected="filters.language"
            :selection="true"
            optionsEmptyPlaceholder="No options placeholder"
            searchPlaceholder="Search"
            selectionType="tags"
            @update="updateData($event, 'language')"
        ></multi-select>
      </div>
      <div class="mb-3">
        <label>Class Type</label>
        <multi-select
            :search="true"
            :options="dataForSelect(data_filters.class_types)"
            :selected="filters.class_type"
            :selection="true"
            optionsEmptyPlaceholder="No options placeholder"
            searchPlaceholder="Search"
            selectionType="tags"
            @update="updateData($event, 'class_type')"
        ></multi-select>
      </div>
      <div class="mb-3">
        <label>Categories</label>
        <multi-select
            :search="true"
            :options="dataForSelect(data_filters.categories)"
            :selected="filters.category"
            :selection="true"
            optionsEmptyPlaceholder="No options placeholder"
            searchPlaceholder="Search"
            selectionType="tags"
            @update="updateData($event, 'category')"
        ></multi-select>
      </div>
      <div class="mb-3">
        <label>Tags</label>
        <multi-select
            :search="true"
            :options="dataForSelect(data_filters.tags)"
            :selected="filters.tag"
            :selection="true"
            optionsEmptyPlaceholder="No options placeholder"
            searchPlaceholder="Search"
            selectionType="tags"
            @update="updateData($event, 'tag')"
        ></multi-select>
      </div>
    </template>
    <template #footer-wrapper>
      <div class="d-flex my-3 align-items-center justify-content-center">
        <CButton color="primary" class="mx-auto"
                 @click="apply"
        >
          Apply
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
import MultiSelect from "@/components/MultiSelect";
export default {
  name: "FiltersModal",
  components: {MultiSelect},
  data() {
    return {
      renderIteration: 0,
      filters: {
        search: '',
        instructor: [],
        class_type: [],
        language: [],
        tag: [],
        category: [],
        status: null,
      },
      data_filters: {
        instructors: [],
        languages: [],
        tags: [],
        categories: [],
        class_types: [],
      },
      statuses: [
        {
          value: null,
          label: 'All'
        },
        {
          value: 'released',
          label: 'Released'
        },
        {
          value: 'draft',
          label: 'Draft'
        },
        {
          value: 'ready',
          label: 'Ready'
        },
        {
          value: 'filmed',
          label: 'Filmed'
        },
      ],
      showModal: false,
      step: 1,
      finished: false,
      data_loading: true,
    }
  },
  methods: {
    open(filters) {
      document.querySelector('body').classList.add('modal_open')
      this.showModal = true;
      this.renderIteration++;//we need increase this value used as key for modal top tag to rerender all multiselect tags
      Object.assign(this.filters, filters);
      if(this.data_loading) this.getData();
    },
    closeModal() {
      this.$emit('modal:close');
      document.querySelector('body').classList.remove('modal_open')
    },
    apply() {
      this.showModal = false;
      this.$emit('apply', this.filters);
      document.querySelector('body').classList.remove('modal_open')
    },
    getData() {
      this.$http.get('/sessions/data').then(({data}) => {
        this.data_filters = data;
        this.$store.commit('set', ['classesFilterData', data]);
      }).finally(() => {
        this.data_loading = false;
      })
    },
    updateData(data, model) {
      this.filters[model] = data;
    },
    dataForSelect(data) {
      return data.map(item => {
        return {
          value: item.id,
          text: item.name
        }
      })
    },
  },
}
</script>

<style scoped>
</style>