<template>
  <CModal
      :show.sync="showModal"
      centered
      @update:show="closeModal"
      title="Filters"
  >

    <label>Status</label>
    <CMultiSelect
        v-if="showModal"
        :options="dataForSelect"
        :selected="filters.active"
        :selection="true"
        inline
        optionsEmptyPlaceholder="No options"
        searchPlaceholder="Search"
        selectionType="tags"
        @update="updateData"
    ></CMultiSelect>

<!--    <CSelect v-model="filters.active" :options="options" label="Active" @update:value="updateData($event, 'active')"></CSelect>-->

    <template #footer-wrapper>
      <div class="d-flex my-3 align-items-center justify-content-center">
        <CButton color="primary" class="mx-auto"
                 @click="apply"
        >
          Apply
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "FiltersModal",
  data() {
    return {
      options: [
        // {value: null, label: 'All'},
        {value: 1, label: 'Active'},
        {value: 0, label: 'Blocked'},
      ],
      filters: {
        search: '',
        active: [],
      },
      items: [],
      showModal: false,
      step: 1,
      finished: false,
      loading_roles: true,
    }
  },
  methods: {
    open(filters) {
      this.showModal = true;
      Object.assign(this.filters, filters);
    },
    closeModal() {
      this.$emit('modal:close');
      document.querySelector('body').classList.remove('modal_open')
    },
    apply() {
      this.showModal = false;
      this.$emit('apply', this.filters);
      document.querySelector('body').classList.remove('modal_open')
    },
    updateData(data, key) {
      this.filters.active = data;
    }
  },
  computed: {
    dataForSelect() {
      return this.options.map(item => {
        return {
          value: item.value,
          text: item.label
        }
      })
    },
  }
}
</script>

<style scoped>
</style>