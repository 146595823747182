<template>
  <div class="c-app flex-row align-items-center ">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8" lg="5">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4 px-5">
              <CForm class="d-flex flex-column" v-if="validToken">
                <p class="text-center font-weight-bold mb-4 text-uppercase">Forgot Password?</p>
                <CInput
                    :placeholder="'Password'"
                    :type="passwordOpen ? 'text': 'password'"
                    autocomplete="new-password"
                    v-model="formData.password"
                    :addInputClasses="{'is-invalid': hasError('password', 'formData')}"
                    :invalid-feedback="getError('password', 'formData')"
                >
                  <template #append-content>
                    <i class="fas " :class="{'fa-eye': !passwordOpen, 'fa-eye-slash': passwordOpen}" @click="passwordOpen = !passwordOpen"/>
                  </template>
                </CInput>
                <CInput
                    :placeholder="'Confirm Password'"
                    :type="passwordOpen ? 'text': 'password'"
                    autocomplete="new-password"
                    class="mb-4"
                    v-model="formData.password_confirmation"
                    :addInputClasses="{'is-invalid': hasError('password_confirmation', 'formData')}"
                    :invalid-feedback="getError('password_confirmation', 'formData')"
                >
                  <template #append-content>
                    <i class="fas " :class="{'fa-eye': !passwordOpen, 'fa-eye-slash': passwordOpen}" @click="passwordOpen = !passwordOpen"/>
                  </template>
                </CInput>
                <p class="text-danger" v-if="hasError('email')">{{getError('email')}}</p>
                <CButton color="primary" class="px-5 mb-3 align-self-center text-uppercase" @click="save">Send</CButton>
              </CForm>
              <div class="d-flex justify-content-center" v-if="checkingToken">
                <CSpinner></CSpinner>
              </div>
              <div v-if="!validToken && !checkingToken" class="text-center">
                Link was expired
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
  import hasApiValidation from "@/mixins/hasApiValidation";
  import { maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

  export default {
    name: 'ResetPassword',
    mixins: [hasApiValidation],
    data() {
      return {
        formData: {
          token: '',
          email: '',
          password: '',
          password_confirmation: '',
        },
        checkingToken: true,
        passwordOpen: false,
        loading: true,
        validToken: false,
      }
    },
    validations: {
      formData: {
        password: {
          required: required,
          minLength: minLength(8),
          maxLength: maxLength(32),
        },
        password_confirmation: {
          required: required,
          sameAs: sameAs('password'),
          minLength: minLength(8),
          maxLength: maxLength(32),
        }
      }
    },
    mounted() {
      this.formData.token = this.$route.query.token  ?? '';
      this.formData.email = this.$route.query.email ?? '';
      this.checkToken();
    },
    methods: {
      checkToken() {
        this.$http.get('/auth/reset-password', {
          params: {
            token: this.formData.token,
            email: this.formData.email
          }
        })
            .then(() => {
              this.validToken = true;
            })
            .finally(() => {
              this.checkingToken = false;
              this.loading = false;
            })
      },
      save() {
        this.loading = true;
        this.setErrors({});
        this.$http.post('/auth/reset-password', this.formData)
            .then(({data}) => {
              this.setErrors({});
              this.$noty.success(data.message);
              this.$router.push('login');
            })
            .catch(({response}) => {
              if (response && response.data.message) {
                this.$noty.error(response.data.message);
              }
              this.setErrors(response.data.errors);
            })
            .finally(() => {
              this.loading = false;
            });
      },
      validate() {
        return !this.$v.formData.$anyError;
      },
    },
    watch: {
      'formData.password': function () {
        this.clearError('password');
        this.$v.formData.password.$touch();
      },
      'formData.password_confirmation': function () {
        this.$v.formData.password_confirmation.$touch();
      },
    }
  }
</script>
