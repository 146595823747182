<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!id" class="mb-0">New Role</h2>
      <h2 v-else class="mb-0">Edit Role</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.role_loading">
        <CInput
            label="Role"
            v-model="new_role.name"
            :addInputClasses="{'is-invalid': hasError('name')}"
            :invalid-feedback="getError('name')"
        ></CInput>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "NewAdmin",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_role: {
        name: '',
      },
      role_loading: false,
    }
  },
  mounted() {
    if (this.id) {
      this.role_loading = true;
      this.fetchRole(this.id);
    }
  },
  methods: {
    fetchRole(id) {
      this.$http.get('/roles/' + id).then(({data}) => {
        this.new_role.id = data.data.id
        this.new_role.name = data.data.name;
      }).finally(() => {
        this.role_loading = false;
      })
    },
    submit() {
      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.setErrors({});
      this.$http.post('/roles', this.new_role).then((data) => {
        this.$router.push({name: 'Roles'}).then(()=>{
          this.$noty.success('Created');
        })
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    update() {
      this.setErrors({});
      this.$http.put('/roles/' + this.id, this.new_role).then((data) => {
        this.$router.push({name: 'Roles'}).then(()=>{
          this.$noty.success('Updated');
        })
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    }
  },
  computed: {
    dataForSelect() {
      return this.roles.map(item => {
        return {
          value: item.id,
          text: item.name
        }
      })
    },
  }
}
</script>

<style scoped>

</style>