<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">Enterprise Builds</h2>
      <CButton @click="newBuild" class="ml-auto mr-3" color="primary" >New Build</CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #path="data">
          <td>
            <a v-if="data.item.path" :href="data.item.path" target="_blank" download>Download</a>
            <span v-else>Deleted</span>
          </td>
        </template>
        <template #created_at="data">
          <td>
            {{data.item.created_at ? $moment(data.item.created_at).format('YYYY-MM-DD HH:mm') : ''}}
          </td>
        </template>
        <template #actions="data">
          <td>
            <div class="d-flex" v-if="data.item.path">
              <CButton size="sm" color="danger" class="text-white" @click="deleteBuild(data.item.id)">Delete</CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDeleteBuild"></delete-confirm-modal>
    <upload-build-modal ref="upload_modal" @saved="fetchData"></upload-build-modal>
  </CCard>
</template>

<script>
import externalTable from "../../mixins/externalTable";
import DeleteConfirmModal from "./DeleteConfirmModal";
import UploadBuildModal from "./UploadBuildModal.vue";

export default {
  name: "EnterpriseBuilds",
  components: {UploadBuildModal, DeleteConfirmModal},
  mixins: [externalTable],
  data() {
    return {
      fields: [
        {
          key: 'version', label: 'Version',
        },
        {
          key: 'path', label: 'File',
        },
        {
          key: 'created_at', label: 'Uploaded',
        },
      ],
      url: '/enterprise-builds'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    newBuild() {
      this.$refs.upload_modal.open()
    },
    deleteBuild(id) {
      this.$refs.delete_modal.open(id);
    },
    confirmDeleteBuild(id) {
      this.$http.delete('/enterprise-builds/' + id).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
  },
  computed: {
    c_fields() {
      let fields = this.fields.slice(0);
      if (this.$auth.check('admins_crud')) {
        fields.push(
            {
              key: 'actions', label: 'Actions',
            });
      }
      return fields
    }
  }
}
</script>

<style scoped>

</style>