<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
    </CCardHeader>
    <CCardBody>
      <vc-calendar
          class="custom-calendar w-100"
          :masks="masks"
          :attributes="attributes"
          disable-page-swipe
          is-expanded
          @update:to-page="changePage"
      >
        <template v-slot:header-title="{title}">
          <span v-if="!loading">{{title}}</span>
          <CSpinner
              style="width:2rem;height:2rem;"
              color="primary"
              grow
              v-else
          />
        </template>
        <template v-slot:day-content="{ day, attributes }">
          <div class="d-flex flex-column h-100 z-10 overflow-hidden hover-full">
            <span class="day-label ">{{ day.day }}</span>
            <div class="flex-grow overflow-y-auto overflow-x-auto">
              <p
                  v-for="attr in attributes"
                  :key="attr.id"
                  class="rounded px-1 py-0 mb-1"
                  :class="{
                    'bg-success text-white': attr.customData.status === 'released',
                    'bg-secondary': attr.customData.status === 'draft',
                    'bg-dark': attr.customData.status === 'ready',
                    'bg-info text-white': attr.customData.status === 'filmed',
                  }"
              >
                {{ attr.customData.name }}
              </p>
            </div>
          </div>
        </template>
      </vc-calendar>
    </CCardBody>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";

export default {
  name: "ClassCalendar",
  mixins: [externalTable],
  data() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    return {
      dates: {
        month: month,
        year: year,
      },
      url: '/sessions/calendar',
      masks: {
        weekdays: 'WWW',
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http.get(this.url, {params: {dates: this.dates}}).then(({data}) => {
        this.data = data.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    changePage(dates) {
      this.dates = dates;
      this.fetchData();
    },
    createClass() {
      this.$router.push({name: 'New Category'});
    },
    editClass(id) {
      this.$router.push({name: 'Edit Category', params: {id: id}});
    },
    deleteClass(id) {
      this.$refs.delete_modal.open(id);
    },
    confirmDelete(id) {
      this.$http.delete('/categories/' + id + '').then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    hide(id) {
      this.$http.put('/categories/' + id + '/hide').then(() => {
        this.$noty.success('Updated!');
        this.fetchData()
      })
    },
    show(id) {
      this.$http.put('/categories/' + id + '/show').then(() => {
        this.$noty.success('Updated!');
        this.fetchData()
      })
    },
    saveOrder() {
      this.$http.put('/categories/order', {
        categories: this.data.map(item => {
          return item.id
        })
      }).then(() => {
        this.$noty.success('Updated!');
        this.fetchData()
      })
    }
  },
  computed: {
    attributes() {
      return [
        // Attributes for todos
        ...this.data.map(item => ({
          dates: item.release_date,
          customData: item
        })),
      ];
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}


</style>