var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex justify-content-end align-items-center"},[_c('h2',{staticClass:"text-uppercase font-weight-bold mr-auto"},[_vm._v("Enterprise Users")]),(_vm.$auth.check('instructors_create_invite'))?_c('CButton',{staticClass:"ml-auto mr-3",attrs:{"color":"primary"},on:{"click":_vm.createUser}},[_vm._v(" Add New ")]):_vm._e(),_c('CInput',{staticClass:"mb-0",attrs:{"placeholder":"Search"},on:{"update:value":_vm.searchChange},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0",attrs:{"items":_vm.data,"fields":_vm.c_fields,"pagination":false,"sorter":{external: true},"loading":_vm.loading,"no-items-view":{noItems: 'No results found'}},on:{"update:sorter-value":_vm.sortingChange},scopedSlots:_vm._u([{key:"active",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.item.active ? 'Active' : 'Blocked')+" ")])]}},{key:"actions",fn:function(data){return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('CButton',{staticClass:"text-white mr-2",attrs:{"size":"sm","color":"info"},on:{"click":function($event){return _vm.viewUserSubscription(data.item.id)}}},[_vm._v(" Subscriptions ")]),_c('CDropdown',{attrs:{"color":"info","size":"sm","toggler-text":"","custom-popper-options":{
                  placement: 'bottom-end',
                  container: 'body',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 0]
                      }
                    },
                    {
                      name: 'flip',
                      enabled: true
                    },
                    {
                        name   : 'preventOverflow',
                        enabled: false,
                        options: {
                            padding: 10,
                            boundary    : _vm.getBody(),
                            altBoundary : true,
                            rootBoundary: 'viewport',
                        }
                    }
                  ]
                }},scopedSlots:_vm._u([{key:"toggler-content",fn:function(){return [_c('span',[_vm._v("Actions")])]},proxy:true}],null,true)},[_c('CDropdownItem',{on:{"click":function($event){return _vm.editUser(data.item.id)}}},[_vm._v("Edit ")]),(data.item.active)?_c('CDropdownItem',{on:{"click":function($event){return _vm.blockUser(data.item.id)}}},[_vm._v("Block ")]):_c('CDropdownItem',{on:{"click":function($event){return _vm.unblockUser(data.item.id)}}},[_vm._v("Unblock ")]),(data.item.email && !data.item.email_verified_at)?_c('CDropdownItem',{on:{"click":function($event){return _vm.resendVerification(data.item)}}},[_vm._v("Resend Verification ")]):_vm._e(),(data.item.email && data.item.email_verified_at)?_c('CDropdownItem',{on:{"click":function($event){return _vm.resetPassword(data.item)}}},[_vm._v(" Reset Password ")]):_vm._e(),(!data.item.email_verified_at)?_c('CDropdownItem',{on:{"click":function($event){return _vm.verify(data.item)}}},[_vm._v("Verify")]):_vm._e(),_c('CDropdownItem',{on:{"click":function($event){return _vm.auth_admin(data.item)}}},[_vm._v("Login as User")])],1)],1)])]}},{key:"enter_code",fn:function(ref){
                var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.enter_code)+" ")])]}},{key:"active_subscriptions_count",fn:function(ref){
                var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.active_subscriptions_count || 0)+" ")])]}},{key:"active_tablets_count",fn:function(ref){
                var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.active_tablets_count || 0)+" ")])]}},{key:"no-items-view",fn:function(){return [_vm._v(" No users found ")]},proxy:true}])}),_c('pagination',{staticClass:"mr-3",attrs:{"current-page":_vm.options.page,"last-page":_vm.options.last_page},on:{"paginate":_vm.pageChange}})],1),_c('delete-confirm-modal',{ref:"block_modal",on:{"confirmed":_vm.confirmBlockUser}}),_c('delete-confirm-modal',{ref:"delete_modal",on:{"confirmed":_vm.confirmDeleteUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }