<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between">
      <h2 class="mb-0">Segment Marker</h2>
      <div class="d-flex align-items-center ">
        <CButton color="secondary"  class="mr-3" @click="back()">
          <i class="fa fa-arrow-left"></i>
          Back
        </CButton>
        <CButton color="primary" class="mr-3" @click="save()">
          Save
        </CButton>
        <CButton color="primary" variant="outline" @click="save(true)">
          Finish
        </CButton>
      </div>
    </CCardHeader>
    <CCardBody>
      <template v-show="audio_loaded">
        <div class="row justify-content-between mb-2">
          <div class="col-3 col-lg-2 col-xl-1">
            <input class="form-control form-control-sm flex-grow-0 text-center  bg-white" :value="currentTime" readonly>
          </div>
          <div class="col-3 col-lg-2 col-xl-1">
            <input class="form-control form-control-sm flex-grow-0 text-center bg-white" :value="maxTime" readonly>
          </div>
        </div>
        <div id="waveform"></div>
        <div class="zoom row justify-content-end">
          <div class="col-3">
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center">
            <i class="fas fa-search-minus"></i>
            <input data-action="zoom" type="range" min="-1" max="30" style="width: 100%; margin: 0 10px" v-model="zoom"/>
            <i class="fas fa-search-plus"></i>
          </div>
          <div class="col-3">
          </div>
        </div>
        <!--      <div id="wave-timeline"></div>-->
        <div class="d-flex align-items-center justify-content-center">
          <a class="btn btn-primary rounded-circle  d-flex justify-content-center align-items-center text-white" style="width:40px; height:40px;" @click="togglePlay()"><i class="fa " :class="{
              'fa-play' : !this.playing,
              'fa-pause' : this.playing,
            }"></i>
          </a>
        </div>
        <div class="d-flex flex-wrap mb-4">
          <div class="list_item d-flex align-items-center mr-4" v-for="segment in segments" :key="'seg_id_'+segment.wave_id">
            <span>{{ segment.name }}</span>
            <CInput
                :value="getSValue(segment.start)"
                @update:value="setSValue($event, segment, 'start')"
                size="sm" :shadow="true" inline class="mb-0 mx-2"
                style="width:60px;"
            ></CInput>
            <div class="" @click="segmentDelete(segment)"><i class="fa fa-times"></i></div>
          </div>
        </div>
        <div class="d-flex flex-wrap mb-4">
          <div class="list_item d-flex align-items-center mr-4" v-for="annotation in annotations"
               :key="'ann_id_'+annotation.id">
            <span>{{ annotation.name }}</span>
            <CInput
                :value="getSValue(annotation.start)"
                @update:value="setSValue($event, annotation, 'start')"
                size="sm" :shadow="true" inline class="mb-0 mx-2"
                style="width:60px"
            ></CInput>
            <div class="" @click="annotationDelete(annotation)"><i class="fa fa-times"></i></div>
          </div>
        </div>
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-center my-2 my-md-4 ">
          <button class="btn btn-primary text-uppercase mr-md-3 rounded-pill py-2 px-4 mb-3 mb-md-0"
                  @click="formSegmentOpen()"
                  v-if="!segmentFormOpen"
                  :disabled="current_select === null || annotationFormOpen || segmentFormOpen|| openEditAnnotation ">
            Create Segment
          </button>
          <button class="btn btn-primary text-uppercase mr-md-3 rounded-pill py-2 px-4 mb-3 mb-md-0"
                  v-if="segmentFormOpen"
                  @click="setEnd()">
            Set End
          </button>
          <button class="btn btn-primary text-uppercase rounded-pill py-2 px-4"
                  @click="formAnnotationOpen()"
                  :disabled="current_select === null || annotationFormOpen || segmentFormOpen || openEditAnnotation">
            Add Note
          </button>
        </div>
      </template>
      <template v-if="data_loaded">
        <audio-segment-form v-show="segmentFormOpen" @saved="regionSaved" :id="id" :movements="movements"
                            :max="max_length"
                            :zones="zones" @delete="segmentDeleteEmpty" ref="new_seg_form"></audio-segment-form>
        <audio-annotation-form v-show="annotationFormOpen" @saved="annotationSaved" :id="id"
                               :max="max_length"
                               ref="new_annotation_form"
                               @delete="annotationDelete"></audio-annotation-form>
        <div class="segment_annotation_wrapper">
          <div class="item_list py-3" v-for="item in all_items" :key="item.type+'-'+(item.wave_id ? item.wave_id : item.id)">
            <template v-if="item.type === 'segment'">
              <div class="item_heading d-flex align-items-center" @click="toggleOpenSegment(item)">
                <h2 class="mb-0 font-weight-medium">{{ item.name }}</h2>
                <div class="segment_color" :style="{'background-color':item.color}"></div>

                <span class="fw-bold h5 ml-3 mb-0">{{ item.start | toHMTime }} - {{ item.end | toHMTime }}</span>
                <div class="ml-auto"><i class="fa fa-chevron-down" :class="{
                'fa-chevron-down': !openEditSegments.includes(item.wave_id),
                'fa-chevron-up': openEditSegments.includes(item.wave_id),
              }"></i></div>
              </div>
              <div class="item_form mt-3" v-if="openEditSegments.includes(item.wave_id)">
                <audio-segment-form :id="id" :movements="movements" :segment="item" @updated="updatedSegment"
                                    :max="max_length"
                                    @delete="segmentDelete"
                                    @errors="resolveError"
                                    ref="edit_seg_form"
                                    :zones="zones"></audio-segment-form>
              </div>
            </template>
            <template v-else>
              <div class="item_heading d-flex align-items-center" @click="toggleOpenAnnotation(item.id)">
                <h2 class="mb-0 font-weight-medium">{{ item.name }}</h2>
                <span class="fw-bold h5 ml-3 mb-0">{{ item.instant | toHMTime }}</span>
                <div class="ml-auto"><i class="fa fa-chevron-down" :class="{
                'fa-chevron-down': openEditAnnotation !== item.id,
                'fa-chevron-up': openEditAnnotation === item.id,
              }"></i></div>
              </div>
              <div class="item_form mt-3" v-if="openEditAnnotation === item.id">
                <audio-annotation-form :id="id" :annotation="item" @updated="updatedAnnotation"
                                       :max="max_length"
                                       :next_char="item.name"
                                       ref="edit_annotation_form"
                                       @delete="annotationDelete"></audio-annotation-form>
              </div>
            </template>
          </div>
        </div>
      </template>
      <CSpinner v-if="!all_loaded">
        Loading
      </CSpinner>
    </CCardBody>
    <delete-item-modal ref="delete_item_modal" @segment-confirm="confirmSegmentDelete"
                       @annotation-confirm="confirmAnnotationDelete"></delete-item-modal>
  </CCard>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import WaveSurferRegions from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import WaveSurferMarkers from "wavesurfer.js/dist/plugin/wavesurfer.markers";
import WaveSurferCursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
// import WaveSurferMinimap from "wavesurfer.js/dist/plugin/wavesurfer.minimap";
// import WaveSurferTimeline from "wavesurfer.js/dist/plugin/wavesurfer.timeline";
import AudioAnnotationForm from "@/views/sessions/segments/AudioAnnotationForm";
import DeleteItemModal from "@/views/sessions/segments/DeleteItemModal";
import AudioSegmentForm from "@/views/sessions/segments/AudioSegmentForm";
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "AudioSegmentation",
  components: {AudioSegmentForm,  DeleteItemModal, AudioAnnotationForm,},
  mixins: [hasApiValidation],
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      zoom: -1,
      playTime: 0,
      annotationFormOpen: false,
      segmentFormOpen: false,
      openEditSegments: [],
      openEditAnnotation: null,
      segments: [],
      annotations: [],
      wavesurfer: null,
      playing: false,
      movements: [],
      zones: [],
      data_loaded: false,
      audio_loaded: false,
      segments_loaded: false,
      annotations_loaded: false,
      current_select: 0,
      audio_name: '',
      url: null,
      details: [],

      max_length: 0,
    }
  },
  watch: {
    zoom: function (val) {
      if (this.wavesurfer) {
        this.wavesurfer.zoom(Number(val));
      }
    }
  },
  async mounted() {
    this.loading = true;
    let data = await this.checkAudio();
    this.loading = false;
    if (data.audio && data.details) {
      this.audio_name = data.name;
      this.url = data.audio;
      this.loading = false;
      this.details = data.details;
    } else {
      this.$router.push({name: 'ClassAudio', params: {id: this.id}}).then(() => {
        this.$noty.warning('Upload audio first');
      })
      return;
    }
    this.drawAudio()
    this.loadData();
    this.loadSegments();
    this.loadAnnotations();
  },
  methods: {
    async checkAudio() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http.get('/sessions/' + this.id + '/audio')
            .then(({data}) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            })
      })
    },
    back() {
      this.$router.push({name: 'Edit Class Audio', params: {id: this.id}})
    },
    save(next = false) {
      this.resolveErrors({});
      this.axios.post(`/sessions/${this.id}/segments/`, {segments: this.segments}).then(() => {
        if (next) {
          this.$router.push({name: 'ClassList'}).then(() => {
            this.$noty.success('Saved!');
          })
        } else {
          this.$noty.success('Saved!');
        }
      }).catch(({response}) => {
        this.resolveErrors(response.data.errors);
        this.$noty.error(response.data.message);
      })
    },
    resolveError(data) {
      let seg = this.segments.find(item => item.wave_id === data.segment.wave_id)
      seg.errors = Object.assign({}, data.errors);
      console.log(seg)
    },
    resolveErrors(errors) {
      this.setErrors(errors);
      let err_keys = Object.keys(this.errors);
      this.segments.forEach((item, i) => {
        let push = false;
        let curr_errors = {};
        err_keys.forEach(k => {
          if (k.startsWith('segments.' + i)) {
            push = true;
            curr_errors[k.substring(('segments.' + i).length + 1)] = this.errors[k]
          }
        })
        item.errors = curr_errors;
        if (push && !this.openEditSegments.includes(item.wave_id)) {
          this.openEditSegments.push(item.wave_id)
        }
      })
    },
    togglePlay() {
      this.playing ? this.wavesurfer.pause() : this.wavesurfer.play()
    },
    toggleOpenSegment(item) {
      if (this.openEditSegments.includes(item.wave_id)) {
        this.openEditSegments = this.openEditSegments.filter(i => i !== item.wave_id);
      } else {
        this.openEditSegments.push(item.wave_id);
      }
    },
    toggleOpenAnnotation(id) {
      if (this.annotationFormOpen) {
        return false;
      }
      if (this.openEditAnnotation !== id) {
        this.openEditAnnotation = id;
      } else {
        this.openEditAnnotation = null;
      }
    },
    segmentDelete(segment) {
      this.$refs.delete_item_modal.open(segment, 'segment')
    },
    segmentDeleteEmpty() {
      this.segmentFormOpen = false;
    },
    annotationDelete(annotation) {
      if (annotation.id) {
        this.$refs.delete_item_modal.open(annotation, 'annotation')
      } else {
        this.openEditAnnotation = null;
        this.annotationFormOpen = false;
      }
    },
    confirmSegmentDelete(segment) {
      this.segmentDeleted(segment.wave_id);
      this.segments = this.segments.filter(item => item.wave_id !== segment.wave_id);
    },
    confirmAnnotationDelete(annotation) {
      this.$http.delete(`/sessions/${this.id}/annotations/${annotation.id}`).then(() => {
        this.annotationDeleted(annotation.id);
      })
    },
    segmentDeleted(wave_id) {
      Object.values(this.wavesurfer.regions.list).find(region => region.id === wave_id).remove();
    },
    annotationDeleted() {
      Object.values(this.wavesurfer.markers.markers).forEach(el => el.innerHTML = '')
      this.loadAnnotations(true)
    },
    regionSaved(region) {
      // this.$noty.success('Segment Created');
      this.segmentFormOpen = null;
      region.type = 'segment';
      region.id = 'new_reg';
      region.errors = null;
      this.segments.push(region);
      this.loadRegion(region)
    },
    annotationSaved() {
      this.$noty.success('Annotation Created');
      this.annotationFormOpen = null;
      Object.values(this.wavesurfer.markers.markers).forEach(el => el.innerHTML = '')
      // this.loadMarker(annotation)
      this.loadAnnotations(true)
    },
    updatedSegment(segment) {
      this.$noty.success('Segment Updated', segment);
      let region = Object.values(this.wavesurfer.regions.list).find(region => region.id === segment.wave_id);
      region.update(segment)
      Object.assign(this.segments.find(item => item.wave_id === segment.wave_id), segment);
      // Object.assign(region, segment);
      // this.openEditSegments = null;
      // Object.values(this.wavesurfer.regions.list).find(region => region.id === segment.id);
      // this.loadRegion(segment)
      // this.loadSegments(false)
    },
    updatedAnnotation() {
      this.$noty.success('Annotation Updated');
      this.openEditAnnotation = null;
      // let an = this.annotations.find(item => item.id === annotation.id);
      Object.values(this.wavesurfer.markers.markers).forEach(el => el.innerHTML = '')
      // Object.values(this.wavesurfer.markers.markers).find(item => item.time === an.instant).el.innerHTML = '';
      // this.loadMarker(annotation)
      this.loadAnnotations(true)
    },
    loadSegments(all = true) {
      this.segments = [];
      this.$http.get(`/sessions/${this.id}/segments`).then(({data}) => {
        this.segments = data.data;
        this.segments_loaded = true;
        if (all) {
          this.loadRegions(this.segments)
        }
      }).catch(({response}) => {
        console.log(response)
      })
    },
    loadAnnotations(all = true) {
      this.annotations = [];
      this.$http.get(`/sessions/${this.id}/annotations`).then(({data}) => {
        this.annotations = data.data;
        this.annotations_loaded = true;
        if (all) {
          this.loadMarkers(this.annotations)
        }
      }).catch(({response}) => {
        console.log(response)
      })
    },
    loadData() {
      this.$http.get('/segments/data').then(({data}) => {
        this.movements = data.movements;
        this.zones = data.zones;
        this.data_loaded = true;
      })
    },
    setEnd() {
      this.$refs.new_seg_form.updateValue(Math.round(this.playTime), 'end')
    },
    formSegmentOpen() {
      this.segmentFormOpen = true;

      let max = this.segments.length > 0 ? Math.max.apply(Math, this.segments.map(function (o) {
        return Number(o.end + 1);
      })) : 0;
      this.$refs.new_seg_form.updateValue(max, 'start')
    },
    formAnnotationOpen() {
      this.annotationFormOpen = true;

      this.$refs.new_annotation_form.updateName(this.next_annotation());
      this.$refs.new_annotation_form.updateValue(this.current_select, 'instant')
    },
    drawAudio() {
      let wavesurfer = WaveSurfer.create({
        container: '#waveform',
        height: 100,
        controls: true,
        scrollParent: true,
        barHeight: 2,
        barGap: 0,
        barRadius: 1,
        normalize: true,
        autoCenter: false,
        // minimap: true,
        backend: 'MediaElement',
        cursorColor: '#C4112E',
        progressColor: '#3f4045',
        waveColor: '#7D8389',
        plugins: [
          WaveSurferCursor.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              'background-color': '#000',
              color: '#fff',
              padding: '2px',
              'font-size': '10px'
            }
          }),
          WaveSurferRegions.create({
            dragSelection: {
              slop: 5
            }
          }),
          WaveSurferMarkers.create()
          // WaveSurferMinimap.create({
          //   height: 30,
          //   waveColor: '#ddd',
          //   progressColor: '#999',
          //   cursorColor: '#999'
          // }),
          // WaveSurferTimeline.create({
          //   container: '#wave-timeline'
          // })
        ]
      });
      this.wavesurfer = wavesurfer;
      wavesurfer.on('play', () => {
        this.playing = true;
      });
      wavesurfer.on('pause', () => {
        this.playing = false;
      });
      wavesurfer.on('audioprocess', () => {
        this.playTime = this.wavesurfer.getCurrentTime();
      });
      wavesurfer.zoom(this.zoom)
      wavesurfer.load(
          // '/157____how.mp3',
          this.url,
          this.details
      );

      /* Regions */

      wavesurfer.on('ready', () => {
        this.audio_loaded = true;
        this.max_length = wavesurfer.getDuration()

        // wavesurfer.enableDragSelection({
        //   color: randomColor(0.1)
        // });

        // fetch('https://wavesurfer-js.org/example/annotation/annotations.json')
        //     .then(r => r.json())
        //     .then(data => {
        //       this.loadRegions(data);
        //     });
      });
      wavesurfer.on('seek', (percent) => {
        this.current_select = Math.floor(this.wavesurfer.getDuration() * percent);
        this.playTime = Math.floor(this.wavesurfer.getDuration() * percent);
        if (this.openEditSegments.length === 1 && this.$refs.edit_seg_form.length > 0) {
          let elem = this.$refs.edit_seg_form[0];
          elem.updateValue(this.current_select, 'end')
        } else {
          this.$refs.new_seg_form.updateValue(this.current_select, 'end')
        }
        if (this.openEditAnnotation && this.$refs.edit_annotation_form.length > 0) {
          let elem = this.$refs.edit_annotation_form[0];
          elem.updateValue(this.current_select, 'instant')
        } else if (this.annotationFormOpen && this.$refs.new_annotation_form) {
          this.$refs.new_annotation_form.updateValue(this.current_select, 'instant')
        }
      });
      wavesurfer.on('region-click', (region) => {
        // region.play();
        // e.stopPropagation();
        if (!this.openEditSegments.includes(region.id)) {
          this.openEditSegments.push(region.id)
        }
      });
      wavesurfer.on('marker-click', (marker, e) => {
        e.stopPropagation();
        // this.openEditAnnotation = marker.data.id
      });

      // wavesurfer.on('region-click', editAnnotation);

      wavesurfer.on('region-created', (region) => {
        if (!region.data.id) {
          this.segments.push({
            start: region.start.toFixed(0),
            end: region.end.toFixed(0),
            color: 'rgba(0,0,0,0.1)',
            drag: true,
            resize: true,
            id: 'new_reg',
            wave_id: region.id,
            name: 'New Region',
            movement: null,
            cadence_range: {
              min: null,
              max: null
            },
            zone: null,
            max_heart_rate: {
              min: null,
              max: null
            },
            pst: {
              min: null,
              max: null
            },
            rpe: null,
            type: 'segment',
            errors: null,
          })
        }
      });

      wavesurfer.on('region-update-end', (region) => {
        let updated_region = this.segments.find(item => item.id === region.id || item.wave_id === region.id);
        if (updated_region) {
          updated_region.start = region.start.toFixed(0);
          updated_region.end = region.end.toFixed(0);
        }
      })
      // wavesurfer.on('region-removed', saveRegions);
      wavesurfer.on('region-in', () => {
        console.log('region-in')
      });

      wavesurfer.on('region-play', function (region) {
        region.once('out', function () {
          // wavesurfer.play(region.start);
          wavesurfer.pause();
        });
      });
      window.addEventListener('resize', wavesurfer.util.debounce(function () {
        wavesurfer.empty();
        wavesurfer.drawBuffer();
      }, 150));

    },
    loadMarkers(markers) {
      markers.forEach((marker) => {
        this.loadMarker(marker)
      });
    },
    loadRegions(regions) {
      regions.forEach((region) => {
        this.loadRegion(region)
      });
    },
    loadRegion(region) {
      let item = {
        data: {
          id: region.id,
        },
        drag: true,
        resize: true,
        color: region.color,
        start: region.start,
        end: region.end,
      }
      let new_reg = this.wavesurfer.addRegion(item);
      region.wave_id = new_reg.id
    },
    loadMarker(marker) {
      var newDiv = document.createElement("span");
      newDiv.width = 30;
      newDiv.height = 30;
      newDiv.classList.add('markered');
      let item = {
        data: {
          id: marker.id,
        },
        time: marker.instant,
        label: marker.name,
        position: 'top',
        markerElement: newDiv
      }
      this.wavesurfer.addMarker(item);
    },

    getSValue(value) {
      return value ? new Date((value ?? 0) * 1000).toISOString().substr(14, 5) : '00:00'
    },
    setSValue(value, item, key) {
      let a = value.split(':'); // split it at the colons
      item[key] = (+parseInt(a[0])) * 60 + (+parseInt(a[1]));
    },
    next_annotation() {
      let last_item = this.annotations.slice(0).sort((a, b) => (a.name.slice(0, 1).toUpperCase().charCodeAt(0) <= b.name.slice(0, 1).toUpperCase().charCodeAt(0) ? 1 : -1))
      return last_item[0] ? String.fromCharCode(last_item[0].name.slice(0, 1).toUpperCase().charCodeAt(0) + 1) : 'A';
    },
  },
  computed: {
    all_items() {
      let items = this.segments.slice(0);
      return items.concat(this.annotations.slice(0)).sort((a, b) => {
        return (Number(a.start) > Number(b.start)) ? 1 : ((Number(b.start) > Number(a.start)) ? -1 : 0)
      });
    },
    all_loaded() {
      return this.data_loaded && this.audio_loaded && this.segments_loaded && this.annotations_loaded;
    },
    maxTime() {
      if (this.audio_loaded) {
        let s = this.wavesurfer.getDuration();
        let m = Math.floor(s / 60);
        s = Math.round(s % 60);
        if (m < 10) {
          m = '0' + m;
        }
        if (s < 10) {
          s = '0' + s;
        }
        return m + ':' + s;
      }
      return this.audio_loaded ? new Date(this.wavesurfer.getDuration() * 1000).toISOString().substr(14, 5) : '00:00'
    },
    currentTime() {
      return this.audio_loaded ? new Date(this.playTime * 1000).toISOString().substr(14, 5) : '00:00'
    },


  }
}
</script>
<style scoped>

.item_list {
  border-bottom: 1px solid var(--primary);
}
</style>