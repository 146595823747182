<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Instructors</h2>
      <CButton @click="createIns" class="ml-auto mr-3" color="primary" v-if="$auth.check('instructors_create_invite')">
        Add New
      </CButton>
      <CButton @click="openFilters" class="mx-3" color="primary">Filters</CButton>
      <CInput v-model="filters.search" @update:value="fetchData" class="mb-0" placeholder="Search"
              @focusout.stop></CInput>
    </CCardHeader>
    <CCardHeader v-if="filters.status.length > 0">
      Status:
      <span v-for="status in filters.status"
            :key="'f-status-'+status"
            class="badge bg-info text-white mr-2 h6 c-pointer" @click="removeStatusFilter(status)">
                {{ statuses.find(s => s.value === status) ? statuses.find(s => s.value === status).label : '' }} <i
          class="fas fa-times"></i>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #status="{item}">
          <td class="text-center">
            {{ item.status }}
          </td>
        </template>
        <template #full_control="{item}">
          <td class="text-center">
            <input type="checkbox" @click.prevent="changeControl(item)" :checked="item.full_control">
          </td>
        </template>
        <template #app_hidden="{item}">
          <td class="text-center">
            <input type="checkbox" @click.prevent="changeHidden(item)" :checked="item.app_hidden">
          </td>
        </template>
        <template #actions="{item}">
          <td>

            <div class="d-flex">
              <CButton size="sm" color="success" class="text-white mr-2" @click="confirmIns(item.id)"
                       v-if="item.status_key === 0"
              >Confirm
              </CButton>
              <CButton size="sm" color="danger" class="text-white mr-2" @click="rejectInstructor(item.id)"
                       v-if="item.status_key === 0"
              >Reject
              </CButton>
              <CButton size="sm" color="danger" class="text-white  mr-2" @click="blockInstructor(item.id)"
                       v-if="item.status_key === 1">
                Block
              </CButton>
              <CButton size="sm" color="success" class="text-white mr-2" @click="unBlockInstructor(item.id)"
                       v-if="item.status_key === 3 && item.status_key !== 2 && item.status_key !== 0">
                Unblock
              </CButton>
              <CButton size="sm" color="warning" class="text-white mr-2" @click="editIns(item.id)"
                       v-if="item.status_key !== 2">Edit
              </CButton>
              <CButton size="sm" color="danger" class="text-white" @click="deleteIns(item.id)">Delete</CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <filters-modal ref="filters_modal" @apply="changeFilters"></filters-modal>
    <delete-confirm-modal ref="block_modal" @confirmed="blockIns"></delete-confirm-modal>
    <delete-confirm-modal ref="delete_modal" @confirmed="destroyIns"></delete-confirm-modal>
    <delete-confirm-modal ref="reject_modal" @confirmed="rejectIns"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import FiltersModal from "../instructors/FiltersModal";
import DeleteConfirmModal from "../instructors/DeleteConfirmModal";

export default {
  name: "InstructorList",
  components: {DeleteConfirmModal, FiltersModal},
  mixins: [externalTable],
  data() {
    return {
      filters: {
        search: '',
        status: [],
      },
      statuses: [
        {value: 0, label: 'Pending'},
        {value: 1, label: 'Active'},
        {value: 2, label: 'Rejected'},
        {value: 3, label: 'Blocked'},
        {value: 4, label: 'Invited'},
      ],
      fields: [
        {
          key: 'first_name', label: 'First Name',
        },
        {
          key: 'last_name', label: 'Last Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'app_hidden', label: 'Hidden',
        },
        {
          key: 'full_control', label: 'Full Control',
        },
        {
          key: 'status', label: 'Status',
        },
      ],
      url: '/instructors'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    removeStatusFilter(status) {
      this.filters.status = this.filters.status.filter(r => r !== status);
      this.fetchData();
    },
    openFilters() {
      this.$refs.filters_modal.open(this.filters);
    },
    changeFilters(data) {
      this.options.page = 1;
      this.filters = Object.assign({}, this.filters, data);
      this.fetchData();
    },
    createIns() {
      this.$router.push({name: 'Invite Instructor'});
    },
    editIns(id) {
      this.$router.push({name: 'Edit Instructor', params: {id: id}});
    },
    deleteIns(id) {
      this.$refs.delete_modal.open(id);
    },
    changeControl(item) {
      this.$http.put('/instructors/' + item.id + '/control').then(() => {
        this.$noty.success('Changed!');
        this.fetchData()
      })
          .catch(({response}) => this.$noty.error(response.data.message));
    },
    changeHidden(item) {
      this.$http.put('/instructors/' + item.id + '/hidden').then(() => {
        this.$noty.success('Changed!');
        this.fetchData()
      })
          .catch(({response}) => this.$noty.error(response.data.message));
    },
    destroyIns(id) {
      this.$http.delete('/instructors/' + id + '').then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },

    blockInstructor(id) {
      this.$refs.block_modal.open(id);
    },
    unBlockInstructor(id) {
      this.$http.put('/instructors/' + id + '/unblock').then(() => {
        this.$noty.success('Unblocked!');
        this.fetchData()
      })
    },
    blockIns(id) {
      this.$http.put('/instructors/' + id + '/block').then(() => {
        this.$noty.success('Blocked');
        this.fetchData()
      })
    },
    rejectInstructor(id) {
      this.$refs.reject_modal.open(id);
    },
    rejectIns(id) {
      this.$http.put('/instructors/' + id + '/reject').then(() => {
        this.$noty.success('Rejected!');
        this.fetchData()
      })
    },
    confirmIns(id) {
      this.$http.put('/instructors/' + id + '/confirm').then(() => {
        this.$noty.success('Confirmed!');
        this.fetchData()
      })
    },
  },
  computed: {
    c_fields() {
      let fields = this.fields.slice(0);
      if (this.$auth.check('instructors_crud')) {
        fields.push(
            {
              key: 'actions', label: 'Actions',
            });
      }
      return fields
    }
  }
}
</script>

<style scoped>

</style>