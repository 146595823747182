var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex justify-content-between"},[(!_vm.id)?_c('h2',{staticClass:"mb-0"},[_vm._v("New Category")]):_c('h2',{staticClass:"mb-0"},[_vm._v("Edit Category")]),_c('CSelect',{staticClass:"mb-0 mr-3",attrs:{"value":_vm.current_language,"options":[
        { label: 'English', value: 'en' },
        { label: 'Spanish', value: 'es' },
        { label: 'German', value: 'de' },
        { label: 'Dutch', value: 'nl' }
      ],"disabled":!_vm.id},on:{"update:value":_vm.updateLanguage}})],1),_c('CCardBody',[(!this.id || !this.loading)?_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('CInput',{attrs:{"label":"Name","addInputClasses":{ 'is-invalid': _vm.hasError('name') },"invalid-feedback":_vm.getError('name')},model:{value:(_vm.new_category.name),callback:function ($$v) {_vm.$set(_vm.new_category, "name", $$v)},expression:"new_category.name"}}),_c('editor',{attrs:{"api-key":_vm.tiny_key,"init":{
          height: 500,
          menubar: false,
          plugins: ['autolink link code '],
          toolbar: 'undo redo | bold italic underline | link |  removeformat'
        }},model:{value:(_vm.new_category.description),callback:function ($$v) {_vm.$set(_vm.new_category, "description", $$v)},expression:"new_category.description"}}),(_vm.hasError('description'))?_c('div',{staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(_vm.getError("description"))+" ")]):_vm._e(),_c('CButton',{staticClass:"mt-3",attrs:{"type":"submit","color":"primary"}},[_vm._v("Save")])],1):_c('div',{staticClass:"mb-3"},[_c('CSpinner',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"color":"primary","grow":""}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }